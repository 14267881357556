import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Hashtag } from "../../constants/hashtags.constants";
import { Sector } from "../../constants/sectors.constants";
import { ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { AaaidpAnimationImagesQuery } from "../../types/generated";

export const ProjectMetaInfoTBMAnimation: ProjectMetaInfo = {
  name: "tbm-animation",
  displayName: "TBM Animation",
  abbreviation: "TBM Animation",
  subtitle: "Animation produced for TBM",
  // TODO
  description: "Animation produced for Total Brand Management (TBM) Solutions.",
  categories: [
    Category.design,
  ],
  sectors: [
    Sector.eCommerceAndRetail,
  ],
  hashtags: [
    Hashtag.animations,
  ],
  hasPage: true,
  keywords: [
    'TBM',
    'Total Brand Management',
    'animation design',
    'advertisement design',
    'motion graphics',
  ],
}
export const useTBMAnimationInfo = () => {
  const TBMAnimationImages: TbmAnimationImagesQuery = useStaticQuery(graphql`
    query TBMAnimationImages {
      featuredImage: file(relativePath: {eq: "images/tbm-animation/tbm-animation-featured-image.png"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      pageBackgroundImage: file(relativePath: {eq: "images/tbm-animation/tbm-animation-background.png"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
    }
  `)
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoTBMAnimation,
    featuredImage: {
      image: TBMAnimationImages.featuredImage!.childImageSharp,
      title: 'TBM Video Preview',
      alt: 'TBM Video Preview Image',
    },
    pageBackgroundImage: {
      image: TBMAnimationImages.pageBackgroundImage!.childImageSharp,
      title: 'TBM Video Preview',
      alt: 'TBM Video Preview Image',
    },
  }
  return info;
}